import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useConsultantCianChat } from './hooks/useConsultantCianChat';
import {
  closeConsultantCianChat,
  closeErrorMessage,
  openAutorisationMotivationConsultantCianChat,
} from '../../actions/consultantCianChat';
import { AuthorizationMotivationPopup } from '../../components/AuthorizationMotivationPopup';
import { ConsultantChatEntry } from '../../components/ConsultantChatEntry';
import { ConsultantCianChatPopup } from '../../components/ConsultantCianChatPopup';
import {
  isOpenSelector,
  isAuthorizationPopupOpenSelector,
  chatUrlSelector,
  customLabelSelector,
  isLoadingSelector,
  isErrorOpenedSelector,
} from '../../selectors/consultantCianChat';
import { userSelector } from '../../selectors/user';
import {
  trackAuthorizeClick,
  trackChatEntryClick,
  trackChatPopupClose,
  trackNotAuthorizedUserPopupShow,
} from './tracking';
import { ConsultantCianChatErrorPopup } from '../../components/ConsultantCianChatErrorPopup';

export const ConsultantCianChatContainer = () => {
  const dispatch = useDispatch();

  const isOpened = useSelector(isOpenSelector);
  const isErrorOpened = useSelector(isErrorOpenedSelector);
  const isLoading = useSelector(isLoadingSelector);
  const isAuthorizationPopupOpen = useSelector(isAuthorizationPopupOpenSelector);
  const chatUrl = useSelector(chatUrlSelector);
  const trackingCustomLabel = useSelector(customLabelSelector);
  const { isAuthenticated } = useSelector(userSelector);
  const { openChat } = useConsultantCianChat();

  const handleAuthorizationPopupOpen = React.useCallback(() => {
    trackNotAuthorizedUserPopupShow(trackingCustomLabel);
  }, [trackingCustomLabel]);

  const handleAuthorizationButtonClick = React.useCallback(() => {
    trackAuthorizeClick(trackingCustomLabel);
    openChat();
  }, [openChat, trackingCustomLabel]);

  const handleChatEntryClick = React.useCallback(() => {
    trackChatEntryClick();

    if (!isAuthenticated) {
      dispatch(openAutorisationMotivationConsultantCianChat());
      handleAuthorizationPopupOpen();

      return;
    }

    openChat();
  }, [isAuthenticated, openChat, dispatch, handleAuthorizationPopupOpen]);

  const handleAuthorizationPopupClose = React.useCallback(() => {
    dispatch(closeConsultantCianChat());
  }, [dispatch]);

  const handleChatClose = React.useCallback(() => {
    trackChatPopupClose();

    dispatch(closeConsultantCianChat());
  }, [dispatch]);

  const handleErrorClose = React.useCallback(() => {
    dispatch(closeErrorMessage());
  }, [dispatch]);

  return (
    <>
      <ConsultantChatEntry isLoading={isLoading} onClick={handleChatEntryClick} />
      <AuthorizationMotivationPopup
        isOpen={isAuthorizationPopupOpen}
        onClick={handleAuthorizationButtonClick}
        onClose={handleAuthorizationPopupClose}
      />
      {isOpened && <ConsultantCianChatPopup onClose={handleChatClose} url={chatUrl} />}
      {isErrorOpened && <ConsultantCianChatErrorPopup open={isErrorOpened} onClose={handleErrorClose} />}
    </>
  );
};
